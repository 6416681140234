import React from 'react'
import ProgressBar from '../../../ui-components/ProgressBar';
import Placeholder from '../../../ui-components/Placeholder';

import { fileUploaderState } from '../../../state/fileUploader';

import { AppContext } from '../../../lib/app-context'
import { Chapter } from '../../../client/sequence'

interface ContentLoaderProps {
    chapter: Chapter;
    thumbnail: string;
}

export default function ContentLoader({ chapter, thumbnail }: ContentLoaderProps) {

    const { status } = React.useContext(AppContext)
    const fileUploader = fileUploaderState.use(uploaders => chapter && chapter.sid && uploaders[chapter.sid])
    const normalize = progress => Math.max(0, Math.min(100, Math.floor(progress)));

    const initFunction = {
        format: () => chapter?.resized ? 100 : 0,
        transcript: () => chapter?.transcripted ? 100 : 0,
        upload: () => (!fileUploader) ? 100 : 0
    }
    
    const [progress, setProgress] = React.useState(initState)

    React.useEffect(() => {
        setProgress(initState)
        for (const type in initFunction) {
            type && status && status.sequence.on(type, ({ progress, itemId }) => {
                if (progress === undefined) {
                    return
                }
                if (chapter.sid && chapter.sid !== itemId) {
                    return
                }
                setProgress(progresses => ({ ...progresses, [type]: normalize(progress) }))
            })
        }
    }, [status]);

    React.useEffect(() => {
        if (!fileUploader) {
            return;
        }

        const onProgress = progress => progress !== undefined && setProgress(progresses => ({ ...progresses, ['upload']: normalize(progress) }))

        fileUploader.on('progress', onProgress)
        return () => fileUploader.off('progress', onProgress)
    }, [fileUploader]);

    function initState() {
        const init = {};
        const progressKeys = Object.keys(initFunction);
        for (let progressKey of progressKeys) {
            init[progressKey] = initFunction[progressKey]();
        }
        return init
    }

    function getProgress() {
        return Object.values(progress).reduce((a, b) => a + b) / 3
    }

    return (<div className="scene-loader">
        <div style={{ width: '100%' }}>
            <Placeholder width="75%" />
            <Placeholder />
            <Placeholder width="50%" />
        </div>
        <div style={{ width: '120px' }}>
            <ProgressBar type="circular" size="lg" progress={Math.floor(getProgress())} />
        </div>
    </div>)
}

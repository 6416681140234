import React from 'react';
import './Placeholder.scss';

interface PlaceholderProps {
    width: string;
    height: string;
}

export default function Placeholder({ width, height, ...props }: PlaceholderProps & {}) {

    const style = {}
    width && (style.width = width)
    height && (style.height = height)
    return <div className="peech-placeholder" {...props} style={style} />
}

Placeholder.defaultProps = {
    width: '100%',
    height: '1rem',
}
